@import 'normalize';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Poppins:wght@500&display=swap');

$headerFont: 'Poppins', sans-serif;
$bodyFont: 'Open Sans', sans-serif;

$FS40: 40px;
$FS35: 35px;
$FS32: 32px;
$FS30: 30px;
$FS26: 26px;
$FS24: 24px;
$FS20: 20px;
$FS18: 18px;
$FS16: 16px;
$FS14: 14px;
$LH12: 12px;

/* Colours */
$primary: #053d5b;
$secondary: #242424;
$white: #fff;
$grey: #9b9ba2;
$lightGrey: #f7f7f7;
$CTAColor: #5b053d;

/* mixins */

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model; // Safari <= 5
     -moz-box-sizing: $box-model; // Firefox <= 19
          box-sizing: $box-model;
}

// generic transform
@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}

/* general */

body{
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family:$bodyFont;
	p,a{
		font-size: $FS16;
		line-height: $FS16 + $LH12;
	}
}

.site{
	overflow:hidden;
}

a {
	color: $white;
}

.container{
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 25px;
}

.containerWrap{
	margin:0 -25px;
}

.clear{
	clear:both;
}

ul,h1,h2,h3,h4,h5{
	margin:0;
	padding:0;
}

h1,h2,h3,h4,h5{
	font-family:$headerFont;
	color:$secondary;
}

p{
	color:$secondary;
}

.fullWidth{
	width:100%;
	height:auto;
}

.maxWidth{
	max-width:100%;
	height:auto;
}

.centerText{
	text-align:center;

	.subtitle{
		margin: 0;
	}
}

.section{
	padding:100px 0;

	h2{
		font-size:$FS35;
		line-height: $FS35 + $LH12;
	}
}

.section--white{
	@extend .section;
	background:$white;
}

.section--grey{
	@extend .section;
	background:$lightGrey;
}

/* header */

.site-branding{
	width:20%;
	float:left;
}

#site-navigation{
	width:80%;
	float:left;
	text-align:right;
	line-height:140px;

	ul{
		list-style: none;
		li{
			display: inline;
			margin-right: 50px;
			line-height: 0;
			&:last-child{
				margin-right: 0;
			}

			a{
				text-decoration: none;
				position: relative;
				@include transition(all, 0.3s ease);
				font-family: $headerFont;
				text-transform: uppercase;
				color:$white;
				font-size: $FS16;
			}
		}

		
	}

	.menu-item-has-children{
		position:relative;
		padding-bottom:50px;

		ul{
			position: absolute;
		    width: 230px;
		    text-align: center;
		    left: -90px;
		    right: 0;
		    margin: auto;
		    top: 47px;
		    border:1px solid $primary;
		    display: none;

		    li{

		    	a{
		    		background:$primary;
		    		border-bottom:1px solid darken($primary, 5%);
		    		padding:10px 15px;
		    		@include box-sizing(border-box);
		    		display: block;
		    		color:$white !important;
		    		@include transition(all, 0.3s ease);
		    		line-height: 22px;

		    		&:hover{
		    			background:darken($primary, 5%);
		    		}
		    	}
		    }
		}
	}

	.menu-item-has-children:hover > .sub-menu{
		display: block;
	}

	.sub-menu{
		margin-right: 0;
	}
}

.siteLogo{
	width:140px;
	height:auto;
    position: relative;
    top: 10px;
}

.heroInner h1 {
	text-transform: capitalize;
}

.pageHero{
	background-size:cover;
	background-position:center;
	background-repeat: no-repeat;
    margin-top: -150px;
    padding: 250px 0 100px;
}

.pageHero--home{
	@extend .pageHero;
	background-image:url("../../public/images/privateinvestigators2.jpg");
}

/* about */
.pageHero--about{
	@extend .pageHero;
	background-image:url("../../public/images/aboutus.jpg");
}

/* bugsweeps */
.pageHero--bug-sweeps{
	@extend .pageHero;
	background-image:url("../../public/images/bug-sweeps/bug-sweeps.jpg");
}

a.contentLink {
    color: #053d5b;
}

/* gallery */
.pageHero--gallery{
	@extend .pageHero;
	background-image:url("../../public/images/camera.jpg");
}

/* contact*/
.pageHero--contact{
	@extend .pageHero;
	background-image:url("../../public/images/contact.jpg");
}

.heroInner{
	width:65%;
	h1{
		color:$white;
		font-size: $FS40;
		line-height: $FS40 + $LH12;
	}

	p{
		color:$white;
		font-size: $FS18;
		line-height: $FS18 + $LH12;
	}

	.btn{
		margin-top: 20px;
	}
}

.btn{
	@include box-sizing(border-box);
	display:inline-block;
	padding:10px 25px;
	font-family:$headerFont;
	font-size:$FS18;
	text-decoration:none;
	cursor: pointer;
	border-radius:2px;
	cursor: pointer;
	@include transition(all, 0.3s ease);
}

.btn--primary{
	@extend .btn;
	background:$primary;
	color:$white;

	&:hover{
		background:darken($primary, 5%);
	}
}

.block{
	@include box-sizing(border-box);
}

.block40{
	@extend .block;
	width:40%;
	float:left;
}

.block50{
	@extend .block;
	width:50%;
	float:left;
}

.block60{
	@extend .block;
	width:60%;
	float:left;
}

.block70{
	@extend .block;
	width:70%;
	float:left;
}

.block30{
	@extend .block;
	width:30%;
	float:left;
}

#about{
	.btn{
		margin-top: 20px;
	}
}

.section--middleCTA{
	padding:120px 0;
	background:url("../../public/images/privateinvestigationservices.jpg") no-repeat;
	background-position:center;
	background-size:cover;

	h2,p, li{
		color:$white;
	}

	h2{
		font-size: $FS40;
		line-height: $FS40 + $LH12;
	}

	p{
		font-size: $FS20;
	}

	ul{
		list-style: none;
		margin-top:40px;
		li{
			display: inline;
			margin:0 20px;
		}
	}

	.phone{
		font-family: $headerFont;
		font-size: $FS20;
	}
}

.serviceWrap{
	margin:60px -25px 0 -25px;
}

.serviceBox{
	width:23%;
	float:left;
	margin:1%;

	img{
		width:100%;
		height: auto;
		border-radius:2px;
	}

	p{
		margin:0;
		color: $grey;
	}

	a{
		font-family: $headerFont;
		color:$secondary;
		text-decoration:none;
		margin-top: 15px;
		display: block;
		@include transition(all, 0.3s ease);

		&:hover{
			color:$primary;
		}
	}
}

.accreds{
	background:darken($primary, 2%);
	padding:20px 0;

	ul{
		list-style: none;
		text-align: center;
		li{
			display: inline;
			margin:0 15px;
			img{
				width: 150px;
				height: auto;
			}
		}
	}
}

.section--mainCTA{
	background:$CTAColor;
	padding:70px 0;

	h2{
		color:$white;
	}

	.block30{
		text-align: right;
	}
}

.site-footer{
	background:$primary;
	padding:70px 0;
	border-bottom:1px solid darken($primary, 2%);

	p, h3, li, a{
		color:$white;
	}

	p, li, a{
		font-size: $FS14;
		line-height: $FS14 + $LH12;
	}

	h3{
		margin-bottom: 20px;
	}

	h3.second{
		margin-top: 30px;
	}

	ul{
		list-style: none;
		li{
			a{
				text-decoration: none;
				font-size: $FS14;
			}
		}
	}

	.services--office{
		li{
			margin:5px 0;
		}

		li.office{
			margin-top: 30px;
		}
	}

	p.smallText{
		font-size: 12px;
		line-height: 24px;
	}

}

.footerWrap{
	margin:0 -25px;
}

.block25{
	width:23%;
	margin:1%;
	float:left;
	@include box-sizing(border-box);
	padding:10px;
}

.socket{
	background:darken($primary, 2%);
	padding:10px 0;
	border-top:1px solid $primary;
	text-align:center;
	
	p,a{
		margin:0;
		padding:0;
		font-size: 12px;
		color: $white;
		font-family: $headerFont;
	}

	a{
		text-decoration: none;
	}
}

.pdLeft10{
	@extend .block;
	padding-left:10%;
}

.pdLeft5{
	@extend .block;
	padding-left:5%;
}

.contentLeft{

	.wp-block-image {
		margin:0;
	}

	img{
		width: 100%;
		height: auto;
		margin:0 0 20px 0 !important;
		padding:0;
		border-radius:2px;
	}

	p,a,li{
		font-size: $FS18;
		line-height: $FS18 + $LH12;
	}

	a{
		color:$primary;
	}

	h2, h3, h4{
		margin:40px 0 20px 0;
		color: $primary;
		font-size: $FS20;
		line-height: $FS20 + $LH12;
	}

	ul{
		margin:30px 20px;
	}

	.btn--primary{
		color: $white;
	}
}


.quickContact{
	@extend .block;
	padding:40px;
	background:$primary;
	border-radius:2px;

	input, textarea{
		width: 100%;
		padding:10px;
		@extend .block;
		border:1px solid #d7d7d7;
	}

	p.lastp{
		margin:0;
		padding:0;
	}

	.wpcf7-submit{
		@extend .btn;
		background:$CTAColor;
		color:$white;
		border:none;
		font-family: $headerFont;
		cursor: pointer;
		@include transition(all, 0.3s ease);

		&:hover{
			background:lighten($CTAColor, 3%);
		}
		
	}

	h2{
		font-size: $FS20;
	    text-align: center;
	    color: $white;
	    margin-bottom: 10px;
	}
}



.contactDetails, .contactPageDetails{
	h2{
		font-size: $FS20;
		line-height: $FS20 + $LH12;
		margin:30px 0 20px 0;
	}

	ul{
		list-style: none;
		li{
			margin:10px 0;
			font-size: $FS16;
			line-height: $FS16 + $LH12;
		}

		li.office{
			margin-top: 30px;
		}
	}
}

.contactDetails{
	background:$CTAColor;
	color:$white;
	padding:40px;
	border-radius:2px;
	margin-top:40px;

	h2{
		color: $white;
		margin-top: 0;
	}
}

.page-template-template-inner, .single-post, .blog, .error404{
	.heroInner{
		width: 100%;
		text-align: center;
	}
}

.page-template-template-contact{
	.section--mainCTA{
		display: none;
	}

	.quickContact {
    	padding: 0;
    	background: #fff;
    	border-radius: 2px;
	}
}

.officeName{
	font-family:$headerFont;
	font-weight:bold;
}

.introText{
	margin-bottom:40px;
	p{
		margin:0;
	}
}

.blogSide{
	ul{
		list-style: none;
		li{
			margin:10px 0;
			border-bottom: 1px solid #d7d7d7;
    		padding-bottom: 10px;

			a{
				font-family:$headerFont;
				color:$primary;
				text-decoration: none;
			}
		}
	}
}

.white--item{
	margin-bottom:80px;

	.blogTitleLink{
		font-size: $FS30;
		line-height: $FS30 + $LH12;
		font-family: $headerFont;
		color:$primary;
		text-decoration: none;
		font-weight: bold;
	}
}

.borderImage{
	border-radius:2px;
}

/* responsive menu */

/* mobile menu styling */

.responsiveNav{
	display:none;
	position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
}

.mobileCta{
	background:darken($primary, 3%);
	text-align:center;
	padding:10px 0;
	display:none;

	p, a{
		color:$white;
		font-size: $FS16;
		font-family: $headerFont;
		text-decoration:none;
		font-weight: 300;
		margin:0;
	}
}

.responsiveLogo{
	float:left;
	width:10%;

	.respLogo{
		margin-left: -23px;
	}

	img{
		max-width: 75px;
		position:relative;
		top:5px;
	}
}

.responsiveCta{
	float:left;
	width:80%;
	text-align:center;
}

.burgerMenu{
	float:left;
	width:10%;
	text-align:right;
	margin-top:29px;
}

.whiteHead{
	background:$primary;
	padding:20px 0;

	p{
		font-family: $headerFont;
		font-size: $FS16;
		color:$white;
		position:relative;
		top:8px;
	}

	a{
		font-family: $headerFont;
		font-size: $FS16;
		color:$white;
		text-decoration:none;
	}
}

.hide {
	max-height: 0 !important;
}

img {
	cursor: pointer;
}

.responsiveList{
	background:$primary;
	text-align:center;
	max-height: 600px;
	overflow: hidden;
	@include transition(all, 0.5s ease);

	ul{
		list-style:none;

		li{

			margin:0 -25px;
			border-top: 1px solid darken($primary, 3%);
    		border-bottom: 1px solid lighten($primary, 3%);

			a{
				color:$white;
				font-size: $FS16;
				font-family: $headerFont;
				text-decoration: none;
				display: block;
				padding: 5px;
			}
		}

		.current_page_item{
			background: $primary;
		}
	}

	.sub-menu{
			
			border-top: none;
    		border-bottom: 1px solid lighten($primary, 1%);

			a{
				background:darken($primary, 3%);
			}
	}
}

.mobMenu{
	position:relative;
	z-index:9999;
}

.site{
	overflow:hidden;
}

.onlyMob{
	display:none;
}

div.wpcf7-response-output {
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    text-align: center !important;
}

.page-id-34{
	.block40{
		display: none;
	}

	#services{
		display: none;
	}
}

/* media queries */

/* 
* ================================================
* 					Media Queries
* ================================================ */

	 	
	 	
/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) ----- iPHONE 6 AND 6 PLUS */
@media only screen and (max-width: 479px) {

	

	.onlyMob{
		display: block;
		position: absolute;
		bottom: -20px;
		right: 25px;
		left: 25px;
		text-align: center;
	}
	

	.pageHero{
		padding:100px 0;
		margin-top:160px !important;

		.heroInner{
			width: 100%;
			text-align: center;

			h1{
				font-size: $FS30;
				line-height: $FS30 + $LH12;
			}
		}
	}
	

	.block40, .block50, .block60, .block70, .block30, .block25{
		width: 100%;
	}
	
	.section{
		h2,h3,h4{
			font-size: $FS24;
			line-height: $FS24 + $LH12;
		}
	}

	#about{
		.secondBlock{
			margin-top: 40px;
		}
	}

	.pdLeft5{
		padding-left:0;
	}

	.btn{
		text-align:center;
		width: 100%;
		@include box-sizing(border-box);
	}
	
	.section--middleCTA{
		.middleCTA{
			li{
				display: block;
				.btn{
					margin-top: 30px;
				}
			}
		}
	}

	.serviceWrap {
    	margin: 40px 0;
	}

	.serviceBox{
		width: 100%;
		margin:20px 0;
	}

	.section--mainCTA{
		text-align: center;

		.block30{
			margin-top: 20px;
		}
	}

	.accreds ul li{
		display: block;
		margin:15px 0;
	}

	.site-footer{
		padding:30px 0 20px 0;
	}

	.footerWrap{
		margin:0;
	}

	.block25{
		margin:20px 0;
	}

	.socket{
		text-align: center;
		p,a{
			font-size: 10px;
		}
	}

	.quickContact{
		margin-top: 40px;
	}

	.section{
		padding:60px 0;
	}

	.white--item .blogTitleLink{
		font-size: $FS24;
		line-height: $FS24 + $LH12;
	}

	.introText{
		margin-bottom: 0;
	}
}

/* Mobile Landscape Size to Tablet Portrait (devices and browsers) */
@media only screen and (min-width: 480px) and (max-width: 850px) {

	
	.pageHero{
		padding:100px 0;
	}

	.heroInner {
	    width: 100%;
	}

	.serviceBox{
		width: 42%;
		margin:1% 4%;
	}
	
	.section--mainCTA{
		.btn--primary{
			margin-top: 15px;
		}
	}

	.accreds ul li img {
    	width: 120px;
	}

	.footerLogo{
		max-width: 100px;
	}

	
	
}





/* All Mobile Sizes (devices and browser) */
@media only screen and (max-width: 767px) {

	/* shows mobile cta */

	.responsiveCta{
		display: none;
	}

	.responsiveLogo{
		width: 70%;
	}

	.burgerMenu{
		width: 30%;
	}

	.mobileCta{
		display: block;
	}
	

}	




	
/* Ipad Portrait */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {


	.pageHero{
		padding:100px 0;
	}

	.heroInner {
	    width: 100%;
	}

	.serviceBox{
		width: 42%;
		margin:1% 4%;
	}
	
	.section--mainCTA{
		.btn--primary{
			margin-top: 15px;
		}
	}

	.accreds ul li img {
    	width: 120px;
	}

	.footerLogo{
		max-width: 100px;
	}
	
}	


/* Ipad Landscape */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {  
	


}

		

/* Smaller than standard 960 (devices and browsers) */
@media only screen and (max-width: 989px) {

	/* hide normal menu  and show mobile menu */

	#masthead{
		display: none;
	}

	.responsiveNav{
		display: block;
	}

	.pageHero{
		margin-top: 115px;
	}
	
}	

.footerLogo {
	max-width: 100px;
}

@media only screen and (min-width: 768px) {
	.footerLogo {
		max-width: 140px;
	}
}
